import { useAtomValue, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";

import type { ErrorResponse } from "@/@types/login";
import { authorizationConfirm } from "@/api/authorization-confirm";
import {
  AttributesState,
  ConsentState,
  userFlowState,
} from "@/contexts/RequestContext";

import { path } from "@/routes";

import { AtlasTracking } from "nid-common";
import { useLeaveInteraction } from "./useLeaveInteraction";

export const useAuthorizationConfirm = () => {
  const navigate = useNavigate();
  const userFlow = useAtomValue(userFlowState);
  const setAttributesState = useSetAtom(AttributesState);
  const setConsentState = useSetAtom(ConsentState);
  const leaveInteraction = useLeaveInteraction();
  const callConfirmAndNavigateLocation = async (
    interactionId: string,
    replace = false,
  ): Promise<void> => {
    const location = await callConfirmAndGetLocation(interactionId, replace);
    if (typeof location === "string") await leaveInteraction.redirect(location);
  };

  const callConfirmAndGetLocation = async (
    interactionId: string,
    replace = false,
  ): Promise<string | undefined> => {
    try {
      const confirmResponse = await authorizationConfirm(interactionId);

      if (
        (confirmResponse.data.state === "authorized" ||
          confirmResponse.data.state === "redirect_required") &&
        confirmResponse.data.location
      ) {
        userFlow === "login"
          ? AtlasTracking.endSignIn()
          : AtlasTracking.endSignUp();
        return confirmResponse.data.location;
      }
      if (confirmResponse.data.state === "attribute_required") {
        setAttributesState({
          required: confirmResponse.data.required_attributes ?? [],
          current: confirmResponse.data.current_attributes ?? {},
        });
        navigate(path.login.attributes, { replace });
      } else if (confirmResponse.data.state === "consent_required") {
        setConsentState({
          companyName: confirmResponse.data.company_name,
          privacyPolicyLink: confirmResponse.data.privacy_policy_link,
          contents: confirmResponse.data.contents,
          purposeOfUse: confirmResponse.data.purpose_of_use,
        });
        navigate(path.login.consent, { replace });
      } else {
        navigate(path.login.error, { replace });
      }
    } catch (e) {
      if (
        (e as ErrorResponse).status === 403 &&
        (e as ErrorResponse).error === "unauthorized_user"
      ) {
        navigate(`${path.login.error}?code=unauthorized_user`, { replace });
      } else {
        navigate(path.login.error, { replace });
      }
    }
  };

  return {
    callConfirmAndNavigateLocation: callConfirmAndNavigateLocation,
    callConfirmAndGetLocation: callConfirmAndGetLocation,
  };
};
