import { useAtom } from "jotai";

import { InteractionIdState } from "@/contexts/RequestContext";

export const useLeaveInteraction = () => {
  const [currentInteractionIdState, setInteractionIdState] =
    useAtom(InteractionIdState);

  return {
    redirect: async (location: string) => {
      setInteractionIdState({ ...currentInteractionIdState, issued: true });
      window.location.href = location;
      await new Promise((resolve) => setTimeout(resolve, 3000));
    },
  };
};
