import { yupResolver } from "@hookform/resolvers/yup";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { authenticationId } from "@/api/authentication-id";
import {
  defaultSignupEmailState,
  fixEmailState,
  loginEmailState,
  userFlowState,
} from "@/contexts/RequestContext";
import useNidForm from "@/hooks/nidForm";
import { useAuthenticatePasskey } from "@/hooks/useAuthenticatePasskey";
import { path } from "@/routes";
import { AtlasTracking } from "nid-common";
import { useEffect, useState } from "react";

/** Form data interface. */
export interface LoginFormInputs {
  email: string;
}

/** Validation schema. */
const schema = yup.object().shape({
  email: yup.string().required("login.error.requiredEmail"),
});

export const enableAuthenticationPasskey =
  import.meta.env?.VITE_FEATURE_FLAG_AUTHENTICATE_PASSKEY === "true";

/**
 * LoginIdFeature hook
 *
 * @category React hooks
 */
export const useLoginIdFeature = (interactionId: string) => {
  const [loginEmail, setLoginEmail] = useAtom(loginEmailState);
  const setDefaultSignupEmailState = useSetAtom(defaultSignupEmailState);
  const fixEmail = useAtomValue(fixEmailState);
  const setUserFlowState = useSetAtom(userFlowState);
  const navigate = useNavigate();
  const [controller, setController] = useState<AbortController | undefined>(
    undefined,
  );

  const { authenticate, isSubmittingPasskey, isConditionalMediationAvailable } =
    useAuthenticatePasskey(interactionId);

  const onSubmit = async (input: LoginFormInputs): Promise<void> => {
    try {
      const response = await authenticationId(input.email, interactionId);
      if (response.data.opt === "invalid_login_id") {
        AtlasTracking.beginSignInFailed(response.data.opt);
        throw "login.error.invalidEmail";
      }
      if (response.data.opt === "user_not_found") {
        AtlasTracking.beginSignInFailed(response.data.opt);
        setDefaultSignupEmailState(input.email);
        throw "login.error.userNotFound";
      }
      if (
        response.data.state === "login_id_specified" &&
        response.data.opt === "passkey_possession" &&
        enableAuthenticationPasskey
      ) {
        setUserFlowState("login");
        setLoginEmail(input.email);
        AtlasTracking.beginSignIn("passkey");
        navigate(path.login.passkey);
      } else if (response.data.state === "login_id_specified") {
        setUserFlowState("login");
        setLoginEmail(input.email);
        AtlasTracking.beginSignIn("password");
        navigate(path.login.password);
      } else {
        throw new Error(`Unexpected state: ${response.data.state}`);
      }
    } catch (e) {
      if (typeof e === "string") {
        throw e;
      }
      AtlasTracking.beginSignInFailed(e);
      navigate(path.login.error);
    }
  };

  /** Form hook. */
  const {
    register,
    handleSubmit,
    apiError,
    fieldErrors,
    isSubmitting,
    watch,
    setError,
  } = useNidForm<LoginFormInputs, "login">({
    resolver: yupResolver(schema),
    onSubmit: onSubmit,
    defaultValues: {
      email: loginEmail,
    },
  });

  const email = watch("email");
  setDefaultSignupEmailState(email);
  const formHasValue = email.length > 0;

  const errorId = fieldErrors?.email?.message || apiError;

  useEffect(() => {
    // fixEmailでreturnしているのは1passwordなどのパスワードマネージャーがある場合に
    // login_hintのユーザ以外で認証できないようにするため
    if (
      !isConditionalMediationAvailable ||
      !enableAuthenticationPasskey ||
      fixEmail
    ) {
      return;
    }
    const newController = new AbortController();
    setController(newController);

    (async () => {
      await authenticate({
        autofill: true,
        onError: () => setError("email", { message: "login.error.passkey" }),
        signal: newController.signal,
      });
    })();

    return () => newController.abort();
  }, [isConditionalMediationAvailable, enableAuthenticationPasskey, fixEmail]);

  const onAuthenticatePasskey = async () => {
    controller?.abort();
    await authenticate({
      autofill: false,
      onError: () => setError("email", { message: "login.error.passkey" }),
    });
  };

  return {
    form: {
      register,
      handleSubmit,
      isSubmitting,
    },
    fixEmail,
    errorId,
    formHasValue,
    isSubmittingPasskey,
    onAuthenticatePasskey,
  };
};
