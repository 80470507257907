import { postAuthenticationPasskeys } from "@/api/postAuthenticationPasskeys";
import { postPublicKeyCredentialRequestOptions } from "@/api/publicKeyCredentialRequestOptions";
import { appLoaderStateAtom } from "@/contexts/RequestContext";
import { path } from "@/routes";
import { CanceledError } from "axios";
import { useAtom } from "jotai";
import {
  AtlasTracking,
  parseRequestOptionsFromJSON,
  toAuthenticationResponseJson,
} from "nid-common";
import { useWebAuthn } from "nid-common/hooks/useWebAuthn";
import { useNavigate } from "react-router-dom";
import { useAuthenticationConfirm } from "./useAuthenticationConfirm";

export const useAuthenticatePasskey = (interactionId: string) => {
  const { getPublicKeyCredential, isConditionalMediationAvailable } =
    useWebAuthn();
  const [isLoading, setIsLoading] = useAtom(appLoaderStateAtom);
  const navigate = useNavigate();
  const authenticationConfirm = useAuthenticationConfirm();

  const authenticate = async (param: {
    signal?: AbortSignal;
    loginId?: string;
    autofill?: boolean;
    onError: () => void;
  }) => {
    const { signal, loginId, autofill, onError } = param;
    const mediation = autofill ? "conditional" : undefined;
    try {
      const requestOptions = await postPublicKeyCredentialRequestOptions(
        { loginId: loginId },
        signal,
      ).catch((e) => {
        if (e instanceof CanceledError) {
          // リロードなどによるキャンセル
          throw "passkey.authentication.cancelled";
        }
        throw e;
      });

      if (
        loginId &&
        (!requestOptions.data.publicKey.allowCredentials ||
          requestOptions.data.publicKey.allowCredentials.length === 0)
      ) {
        // ログインIDが指定されたがパスキーが存在しない
        AtlasTracking.verifyPasskeyFailed(mediation, "no_allow_credentials");
        throw "passkey.authentication.fatal_error";
      }

      const publicKeyCredential = await getPublicKeyCredential({
        signal,
        mediation: autofill ? "conditional" : undefined,
        publicKey: parseRequestOptionsFromJSON(requestOptions.data.publicKey),
      }).catch((e) => {
        AtlasTracking.beginSignIn("passkey");
        if (e instanceof DOMException && e.name === "AbortError") {
          // signal によるキャンセル
          if (!autofill) {
            AtlasTracking.verifyPasskeyFailed(mediation, "aborted");
          }
          throw "passkey.authentication.cancelled";
        }
        if (e instanceof DOMException && e.name === "NotAllowedError") {
          // ユーザーがパスキーダイアログを閉じた
          if (!autofill) {
            AtlasTracking.closePasskeyPrompt("not_allowed");
          }
          throw "passkey.authentication.cancelled";
        }
        AtlasTracking.closePasskeyPrompt("webauthn_error", e);
        throw new Error("PublicKeyCredentialの取得に失敗しました", {
          cause: e,
        });
      });
      if (publicKeyCredential === null) {
        throw new Error("PublicKeyCredentialはnullになりました");
      }

      // Start Browser Interaction
      setIsLoading(true);
      AtlasTracking.beginSignIn("passkey");
      const response = await postAuthenticationPasskeys(
        {
          interactionId,
          ...toAuthenticationResponseJson(publicKeyCredential),
        },
        signal,
      ).catch((_) => {
        // パスキー認証APIで400または500エラーが発生した
        AtlasTracking.verifyPasskeyFailed(mediation, "invalid_interaction");
        throw "passkey.authentication.fatal_error";
      });

      if (response.data.state === "authenticated") {
        // 認証成功
        AtlasTracking.verifyPasskey(mediation, publicKeyCredential.id);
        await authenticationConfirm.callConfirmAndNavigateLocation(
          interactionId,
        );
      } else if (
        [
          "login_id_specified",
          "login_id_required",
          "challenge_required",
          "password_confirmed",
          "email_confirmation_required",
          "email_send_required",
        ].includes(response.data.state)
      ) {
        // 正しくないパスキーで認証を試みた
        AtlasTracking.verifyPasskeyFailed(mediation, "invalid_passkey");
        throw "passkey.authentication.error";
      } else {
        // 予期しないAPIレスポンス
        throw new Error(`Unexpected response: ${response.data.state}`);
      }
    } catch (e) {
      if (e === "passkey.authentication.cancelled") {
        // no action
      } else if (e === "passkey.authentication.error") {
        onError();
      } else if (e === "passkey.authentication.fatal_error") {
        navigate(path.login.error);
      } else {
        // 予期しないエラー
        AtlasTracking.verifyPasskeyFailed(mediation, e);
        onError();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    authenticate,
    isSubmittingPasskey: isLoading,
    isConditionalMediationAvailable,
  };
};
